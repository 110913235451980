<template>
    <div id="markdown" class="markdown" v-html="markdown">
    </div>
</template>

<script>
import marked from 'marked'

export default {
    computed: {
        markdown() {
            let parsedText = this.markedContent;
            return marked(parsedText);
        },
    },
    props: {
        markedContent: String
    }
}
</script>